import FormGroup from "@/components/FormGroup.vue";
import FormDynamic from "@/components/FormDynamic.vue";
import Container from "@/components/Container.vue";
import Service from "@/services/base.service";
import FormAction from "@/actions/base.form";
import Template from "@/scripts/kta/submission-form-template";

export default {
  mixins: [Template, FormAction],
  components: {
    FormDynamic,
    FormGroup,
    FormAction,
    Container,
  },
  computed: {
    ktasn() {
      const ktasn = JSON.parse(JSON.stringify(this.template));
      this.clearValues(ktasn);
      console.log(ktasn)
      return ktasn;
    }
  },
  methods: {
    async fetchRanks(filters = null) {
      try {
        const BaseService = new Service('master/pangkat');
        const { data } = await BaseService.getListData(filters);
        return data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchMatra(filters = null) {
      try {
        const BaseService = new Service('master/matra');
        const { data } = await BaseService.getListData(filters);
        return data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    submitForm(event) {
      event.preventDefault(); 
      if(confirm('Kirim data pengajuan?')) {
        const isFormValid = this.validateForm();
        if (isFormValid) {
          this.uploadData()
        } else {
          alert('Form tidak valid');
        }
      }
    },
    validateForm() {
      let isValid = true;
      this.biodata.forEach((section) => {
        for (let key in section) {
          section[key].forEach((field) => {
            if (field.required && !field.value) {
              isValid = false;
            }
          });
        }
      });
      this.sinyalemen.forEach((section) => {
        for (let key in section) {
          section[key].forEach((field) => {
            if (field.required && !field.value) {
              isValid = false;
            }
          });
        }
      });
      return isValid;
    },
    async uploadData() {
      // upload attachment
      const attachment = this.getNameValue(this.biodata);
      const uploadFoto = await this.sendAttachment('attachment/upload', 'createData', attachment.foto);

      // upload sinyalemen
      attachment.foto = null
      const sinyalemen = this.getNameValue(this.sinyalemen);
      sinyalemen.nama_lengkap = attachment.nama_lengkap;
      sinyalemen.nama_lengkap = attachment.nama_lengkap;
      sinyalemen.dimensionId = attachment.matra;
      // sinyalemen.file_foto = uploadFoto.id;
      sinyalemen.rankId = attachment.pangkat_korps;
      sinyalemen.attachments = uploadFoto.id;
      sinyalemen.honors = this.kehormatan.map(item => item.value);
      sinyalemen.nip_nrp = this.nrp;
      const person = await this.sendData('person', 'createData', sinyalemen);
      
      // upload card application
      const cardApplication = await this.sendData('card-application', 'createData', {
        person_id: person.id,
        card_type_id: this.cardId,
        status: 'PENDING',
        kta_number: attachment.no_kta,
      });
      
      if (cardApplication.id !== null) {
        this.$router.push({ name: 'submission-kta' });
      }
    },
    addForm(){
      this.kehormatan.push({
        value:'',
        placeholder: 'Tanda Kehormatan',
      })
    },
    removeForm(index){
      this.kehormatan.splice(index, 1);
    },
    clearValues(obj) {
      for (let key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach(this.clearValues);
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.clearValues(obj[key]);
        } else if (key === 'value') {
          obj[key] = '';
        }
      }
    }
  },
  data() {
    return {
      cardId: '',
      cardType: 'KTA',
      nrp: '',
      listPangkat: [],
      biodata: [
        {
          '3': [
            {label: 'Ubah Foto', fileValue: {}, value: '', type: 'file', name: 'foto'},
          ],
          '9': [
            {label: 'No. KTA', value: this.cardType ? 'asdasd' : '', type: 'text', name: 'no_kta' , placeholder: 'No. KTA', required: true},
            {label: 'Nama Lengkap', value: '', type: 'text', name: 'nama_lengkap', placeholder: 'Nama Lengkap', required: true},
            {label: 'Matra',data:[], value: '', type: 'select', name: 'matra', placeholder: 'Matra', required: true},
            {label: 'Pangkat / Korps',data:[], value: '', type: 'select', name: 'pangkat_korps', placeholder: 'Pangkat / Korps', required: true},
            {label: 'Kesatuan', value: '', type: 'text', name: 'kesatuan', placeholder: 'Kesatuan', required: true},
          ]
        }
      ],
      sinyalemen:[
        {
          '12': [
            {label: 'Tinggi', value: '', type: 'text', tail: 'cm', name: 'tinggi', placeholder: 'Tinggi', required: true},
            {label: 'Berat', value: '', type: 'text', tail: 'kg', name: 'berat', placeholder: 'Berat', required: true},
          ],
        },
        {
          '12': [
            {label: 'Rambut', value: '', type: 'text', name: 'rambut', placeholder: 'Rambut', required: true},
            {label: 'Mata', value: '', type: 'text', name: 'mata', placeholder: 'Mata', required: true},
            {label: 'Golongan Darah', value: '', type: 'text', name: 'gol_darah', placeholder: 'Golongan Darah', required: true},
          ],
        },
        {
          '12': [
            {label: 'Tempat Lahir', value: '', type: 'text', name: 'tempat_lahir', placeholder: 'Tempat Lahir', required: true},
            {label: 'Tanggal Lahir', value: '', type: 'date', name: 'tanggal_lahir', placeholder: 'Tanggal Lahir', required: true},
          ],
        },
        {
          '12': [
            {label: 'Agama', value: '', type: 'text', name: 'agama', placeholder: 'Agama', required: true},
          ],
        },
        {
          '12': [
            {label: 'Kontak Darurat', value: '', type: 'text', name: 'kontak_darurat', placeholder: 'Kontak Darurat'},
          ],
        },
        {
          '12': [
            {label: 'Alamat Rumah', value: '', type: 'textarea', name: 'alamat', placeholder: 'Alamat Rumah'},
          ],
        },
      ],
      kehormatan:[
        {
          value: '',
          placeholder: 'Tanda Kehormatan',
        },
      ],
    };
  },
  async created() {
    this.cardId = this.$store.state.kta.cardId;
    this.cardType = this.$store.state.kta.cardType;
    this.nrp = this.$store.state.kta.nrp;
    this.biodata[0]['9'].find(item => item.label === 'Matra').data = await this.fetchMatra();
    this.biodata[0]['9'].find(item => item.label === 'Pangkat / Korps').data = await this.fetchRanks();
    if(this.cardType === null) {
      this.$router.push({ name: 'submission-kta' });
    }
  },
};